import React, { useContext, useEffect } from "react"
import "./contact.css"
import {MdOutlineMail} from "react-icons/md"
import {BsSkype} from "react-icons/bs"
import Form from "../form/Form"
import { ActiveSectionContext } from "../../ActiveSectionContext"
import {useInView} from "react-intersection-observer"

export default function Contact() {

  const {setActiveSection} = useContext(ActiveSectionContext)
  const {ref: sectionRef, inView: sectionVisible} = useInView()

  useEffect(() => {
    if(sectionVisible) setActiveSection("contact")
  }, [sectionVisible])
  

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>

      <div className="container contact__container">
        
        <div ref={sectionRef} className="contact__options">
         
          <article className="contact__option">
            <MdOutlineMail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>enquiries@byndstrategy.tech</h5>
            <a href="mailto:enquiries@byndstrategy.tech">Send an email</a>
          </article>

          <article className="contact__option">
            <BsSkype className="contact__option-icon" />
            <h4>Skype</h4>
            <h5>join.skype.com/invite/tSlsELg4psq9</h5>
            <a href="https://join.skype.com/invite/tSlsELg4psq9">Send a Skype message</a>
          </article>

        </div>

          {/* START OF CUSTOM FORM COMPONENT */}
          <Form />
          
      </div>
    </section>
  )
}
