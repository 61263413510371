import React, {useContext, useEffect} from "react"
import "./introduction.css"
import ME from "../../assets/logoreflection.png"
import {MdWeb} from "react-icons/md"
import {IoIosApps} from "react-icons/io"
import {TbBrandCodesandbox} from "react-icons/tb"
import { ActiveSectionContext } from "../../ActiveSectionContext"
import {useInView} from "react-intersection-observer"

export default function Introduction() {
  
  const {setActiveSection} = useContext(ActiveSectionContext)
  const {ref: sectionRef, inView: sectionVisible} = useInView()

  useEffect(() => {
    if(sectionVisible) setActiveSection("introduction")
  }, [sectionVisible])
  

  return (
    <section id="introduction">
      <h5>
        Quick Intro.
      </h5>
      <h2 ref={sectionRef}>What We Do</h2>
      <div className="container introduction__container">
        <div className="introduction__me">
          <div className="introduction-_me-image">
            <img src={ME} alt="Company Logo" />
          </div>
        </div>
        <div className="introduction__content">
            <p>
            <span className="subhead_normal">BYND Strategy Technologies</span> develops software solutions to support customers' goals in <span className="underline">Digital Transformation</span>. We are passionate about <span className="highlight">Software Development</span> and <span className="highlight">Technology Integration</span> and strive for a Test-Driven Development &#40;TDD&#41; approach in building solutions that improve digital capabiities for customers. We believe in a User-Centered design approach where engaging our customers directly is paramount to deliver quality services and outcomes efficiently.
            </p>

            <div className="introduction__cards">

              <article className="introduction__card">
                <IoIosApps className="introduction__icon" />
                <h5>Full-stack Applications <br />&#40;Web and Mobile&#41;</h5>
                <small>We specialize in developing full-stack solutions that prioritizes user-onboarding and positive user-experiences, helping clients construct applications with responsive front-ends and scalable backend - including apps on native-mobile platforms.</small>
              </article>

              <article className="introduction__card">
                <TbBrandCodesandbox className="introduction__icon" />
                <h5>Blockchain Solutions Development</h5>
                <small>We provide services in designing next-generation web3 solutions to suit clients' business goals. From planning and design to development and deployment, we create customized smart contracts on the blockchain to keep clients' platforms robust and safe.</small>
              </article>

              <article className="introduction__card">
                <MdWeb className="introduction__icon" />
                <h5>Legacy Applications Modernisation</h5>
                <small>From monolith applications to microservices solutions, we help clients redesign, recreate and transition existing systems to modern-day architecture solutions that are future-ready.</small>
              </article>

            </div>
              
        </div>
      </div>
    </section>
  )
}
