import React, {useEffect, useContext } from "react"
import "./nav.css"
import {AiOutlineHome} from "react-icons/ai"
import {CgShapeHexagon} from "react-icons/cg"
import {BiNetworkChart} from "react-icons/bi"
import {BiMessageSquareDetail} from "react-icons/bi"
import {LuHeartHandshake} from "react-icons/lu"
import { ActiveSectionContext } from "../../ActiveSectionContext"


export default function Nav() {

  const {activesection, setActiveSection} = useContext(ActiveSectionContext)

  useEffect(() => {
    setActiveSection("header")
  }, [])

  return (
    <nav>
      <a href="#header" onClick={()=>setActiveSection("header")} className={activesection ==="header" ? "active" : ""}>
        <AiOutlineHome/>
      </a>
      <a href="#introduction" onClick={()=>setActiveSection("introduction")} className={activesection === "introduction" ? "active" : ""}>
        <LuHeartHandshake/>
      </a>
      <a href="#product" onClick={()=>setActiveSection("product")} className={activesection ==="ourproduct" ? "active" : ""}>
        <CgShapeHexagon/>
      </a>
      <a href="#features" onClick={()=>setActiveSection("features")} className={activesection === "features" ? "active" : ""}>
        <BiNetworkChart/>
      </a>
      <a href="#contact" onClick={()=>setActiveSection("contact")} className={activesection === "contact" ? "active" : ""}>
        <BiMessageSquareDetail/>
      </a>
    </nav>
  )
}
