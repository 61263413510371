import React, {useRef} from 'react'
import "./form.css"
import emailjs from "emailjs-com"
export default function Form() {

    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_x07gszc", "template_1003ozg", form.current, 'O1k0Ss8K7HEThkfjE')
    
    e.target.reset()

    }

  return (
    <form ref={form} onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Your Full Name" required />
        <input type="email" name="email" placeholder="Your Email" required />
        <textarea name="message" placeholder="Your Message" required rows="7"></textarea>
        <button type="submit" className="btn btn-primary">SEND MESSAGE</button>
    </form>
  )
}
