import { createContext, useState } from "react"; 

const ActiveSectionContext = createContext()

function ActiveSectionContextProvider(props){

    const [activesection, setActiveSection] = useState()
    
    return (
            <ActiveSectionContext.Provider value={{activesection, setActiveSection}}>
                {props.children}
            </ActiveSectionContext.Provider>
    )

}

export {ActiveSectionContextProvider, ActiveSectionContext}  