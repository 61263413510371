import React, {useContext, useEffect} from "react"
import "./header.css"
import CTA from "./CTA"
import ME from "../../assets/logo.png"
import HeaderSocials from "./HeaderSocials"
import { ActiveSectionContext } from "../../ActiveSectionContext"
import {useInView} from "react-intersection-observer"

export default function Header() {

  const {setActiveSection} = useContext(ActiveSectionContext)
  const {ref: sectionRef, inView: sectionVisible} = useInView()

  useEffect(() => {
    if(sectionVisible) setActiveSection("header")
  }, [sectionVisible])

  return (
    <header id="header">
      <section className="container header__container">
        <h5>Welcome to</h5>
        <h1>BYND Strategy</h1>
        <h2 ref={sectionRef} className="headersubtitle">Bridging Purpose, Process and People.</h2>
        
        <CTA />
        
        <HeaderSocials />

        <div className="me">
            <img src={ME} alt="" />
        </div>

      <a href="#introduction" className="scroll__down" onClick={()=>{setActiveSection("introduction")}}>Scroll Down</a>
      </section>
    </header>
  )
}
