import React from "react"
import Header from "./components/header/Header"
import Nav from "./components/nav/Nav"
import Features from "./components/features/Features"
import Contact from "./components/contact/Contact"
import Footer from "./components/footer/Footer"
import Introduction from "./components/introduction/Introduction"
import Product from "./components/product/Product"
import { ActiveSectionContextProvider } from "./ActiveSectionContext"

export default function App() {
  return (
    <ActiveSectionContextProvider>
        <Header />
        <Nav />
        <Introduction />
        <Product />
        <Features />
        <Contact />
        <Footer />
    </ActiveSectionContextProvider>
  )
}
