import React, {useContext, useEffect} from "react"
import "./product.css"
import ME from "../../assets/logoreflection.png"
import {BiTargetLock} from "react-icons/bi"
import {MdMiscellaneousServices} from "react-icons/md"
import {GiCutDiamond} from "react-icons/gi"
import {GiCubeforce} from "react-icons/gi"
import { ActiveSectionContext } from "../../ActiveSectionContext"
import {useInView} from "react-intersection-observer"

export default function Product() {
  
  const {setActiveSection} = useContext(ActiveSectionContext)
  const {ref: sectionRef, inView: sectionVisible} = useInView()

  useEffect(() => {
    if(sectionVisible) setActiveSection("product")
  }, [sectionVisible])
  

  return (
    <section id="product">
      <h5>
        Our Current Project
      </h5>
      <h2 ref={sectionRef}>Flagship Product</h2>
      <div className="container product__container">
        
        <div className="product__content">
            <p>
            <span className="subhead">BYND</span> is our flagship product that we are currently developing; it empowers private equity and venture capital firms to leverage on <span className="underline"> workflow automation and Blockchain technologies</span> to ensure critical processes are managed and tracked efficiently to completion - thus <span className="highlight">Bridging Yields And Diligence on the Blockchain</span> for greater accountability and ROI.
            </p>
            <div className="product__cards">
              <article className="product__card">
                <MdMiscellaneousServices className="product__icon" />
                <h5>Define</h5>
                <small>Funding parameters and requirements are set up as smart contracts on the blockchain.</small>
              </article>

              <article className="product__card">
                <BiTargetLock className="product__icon" />
                <h5>Track</h5>
                <small>Deals and progress are tracked, highlighting opportunities and detecting anomalies.</small>
              </article>

              <article className="product__card">
                <GiCutDiamond className="product__icon" />
                <h5>Unlock</h5>
                <small>Multi-signatory consensus unlocks funds for verified milestones of each investment.</small>
              </article>

              <article className="product__card">
                <GiCubeforce className="product__icon" />
                <h5>Notify</h5>
                <small>Reports generated, documentation and evidence are traceable on the blockchain.</small>
              </article>
            </div>
              
        </div>
      </div>
    </section>
  )
}
