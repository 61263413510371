import React from "react"
import "./footer.css"
import {FiInstagram} from "react-icons/fi"
import {IoLogoTwitter} from "react-icons/io"

export default function Footer() {
  return (
    <footer>
      <a href="#header" className="footer_logo">BYND Strategy</a>
      <ul className="permalinks">
        <li><a href="#header">Home</a></li>
        <li><a href="#introduction">What is BYND</a></li>
        <li><a href="#features">Product Features</a></li>
        <li><a href="#contact">Contact Us</a></li>
      </ul>
      <div className="footer__socials">
       <a href="https://twitter.com/byndstrategy"target="_blank" rel="noopener noreferrer"><IoLogoTwitter/></a>
       <a href="https://www.instagram.com/byndstrategytech/" target="_blank" rel="noopener noreferrer"><FiInstagram /></a>
      </div>
      <small className="footer__copyright">&copy; Bynd Strategy Technologies. All rights reserved.</small>
    </footer>
  )
}