import React, { useContext, useEffect } from "react"
import "./features.css"
import FEATURES from "../../assets/features.png"
import { ActiveSectionContext } from "../../ActiveSectionContext"
import { useInView } from "react-intersection-observer"

export default function Features() {

  const {setActiveSection} = useContext(ActiveSectionContext)
  const {ref: sectionRef, inView: sectionVisible } = useInView()

  useEffect(() => {
    if(sectionVisible) setActiveSection("features")
  }, [sectionVisible])
  
  return (
    <section id="features">
      <h5>Solution Overview</h5>
      <h2 ref={sectionRef}>Product Features</h2>
      <div className="container features__container">
        <div className="features__content">
        <p>
            We engaged general partners, limited partners and senior executives in the industry and understand their need for greater control over their operations. We are developing a solution that tackles these challenges within a single point of administration.
        </p>
      </div>

      {/* START APPROACH INFO */}
      <div className="features__content">
        <article className="features__card">
          <div className="de">
            <img src={FEATURES} alt="" />
          </div>
        </article>
      </div>
    </div>
    {/* END APPROACH INFO */}
        

    </section>
  )
}
