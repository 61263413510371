import React from "react"
import {FiInstagram} from "react-icons/fi"
import {FaTwitterSquare} from "react-icons/fa"

export default function HeaderSocials() {
  return (
    <div className="header__socials">
        <a href="https://twitter.com/byndstrategy"target="_blank" rel="noopener noreferrer">
            <FaTwitterSquare />
        </a>
        <a href="https://www.instagram.com/byndstrategytech/" target="_blank" rel="noopener noreferrer">
            <FiInstagram />
        </a>
    </div>
  )
}