import {useEffect, useContext} from 'react'
import { ActiveSectionContext } from "../../ActiveSectionContext"

export default function CTA() {

  const {activesection, setActiveSection} = useContext(ActiveSectionContext)

  useEffect(() => {
    setActiveSection("header")
  }, [])

  return (
    <div className="cta">
        <a href="#product" onClick={()=>setActiveSection("product")} className="btn">Our Current Project.</a>
        <a href="mailto:enquiries@byndstrategy.tech" className="btn btn-primary">Let's talk.</a>
    </div>
  )
}
